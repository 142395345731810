
.display-selected {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(var(--input-lookup-font-size) * 2.5);
  padding-inline-start: var(--w-size-30);
  padding-inline-end: var(--w-size-40);
  padding-block: var(--w-size-20);

  justify-content: space-between;

  cursor: pointer;

  border: var(--w-border-neutral);
  background: transparent;

  text-align: left;
  color: inherit;
}

.display-selected:focus {
  box-shadow: var(--w-border-primary);
}

.display-selected::after {
  content: "▼";
  position: absolute;
  right: var(--w-size-20);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
