
.input-date {
  --size: var(--w-font-size-30);
  --state-shadow: var(--w-border-shadow-neutral);
}

.input-date.valid {
  --state-shadow: var(--w-border-shadow-success);
}

.input-date.invalid {
  --state-shadow: var(--w-border-shadow-danger);
}

.input-date {
  width: 100%;
  height: calc(var(--size) * 2.5);
  padding-inline: var(--w-size-30);
  padding-block: var(--w-size-20);

  border: var(--w-border-neutral);
  outline: none;

  font-family: inherit;
  color: inherit;
  font-size: var(--size);
  font-weight: var(--w-font-weight-20);
}

.input-date:focus {
  box-shadow: var(--state-shadow);
}

.input-date.disabled {
  background-color: var(--w-color-neutral-20);
}
